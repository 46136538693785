import { pusherKey, pusherCluster, backendUrl } from '../app-globals';
import Pusher from 'pusher-js';
import Vue from 'vue';
import store from '../store';
import constants from '../constants';

const userData = store.getters.getUser;
const organization = userData.user.organization;

// create pusher instance
const pusher = new Pusher(pusherKey, {
  cluster: pusherCluster,
  wssPort: 443,
  enabledTransports: ['ws'],
  forceTLS: true,
  encrypted: true,
  auth: {
    headers: {
      Authorization: `Bearer ${userData.token}`,
    },
  },
  authEndpoint: `${backendUrl}/api/broadcasting/auth`,
});

Vue.prototype.$pusher = pusher;

// organization channel
var organizationChannel = pusher.subscribe(
  `${constants.SOCKET_ORGANIZATION_CHANNEL}.${organization.external_id}`,
);

// job channel
store.subscribe((mutation, state) => {
  if (mutation.type === 'jobs/saveSocketJobId') {
    var jobChannel = pusher.subscribe(
      `${constants.SOCKET_ORGANIZATION_CHANNEL}.${organization.external_id}.${constants.SOCKET_JOB_CHANNEL}.${state.jobs.socketJobId}`,
    );
    jobChannel.bind(`${constants.SOCKET_JOB_COMMENT_CREATED}`, (e) => {
      store.dispatch('jobs/fetchJobComments', {
        jobId: state.jobs.socketJobId,
        params: {
          limit: 100,
          startPage: 0,
        },
      });
    });
  }
});

organizationChannel.bind(`${constants.SOCKET_SEND_ALERT_MESSAGE}`, (e) => {
  store.dispatch('widgets/fetchAlertHistories', {
    params: {
      limit: 10,
      startPage: 0,
    },
  });
  store.dispatch('notifications/recordMessage', e);
});
organizationChannel.bind(`${constants.SOCKET_RECEIVE_ALERT_RESPONSE}`, (e) => {
  store.dispatch('widgets/addResponseStatus', e);
});
organizationChannel.bind(`${constants.SOCKET_COMPANY_SYNC_COMPLETED}`, (e) => {
  store.dispatch('companies/fetchCompaniesList');
  store.dispatch('notifications/recordMessage', e);
});
organizationChannel.bind(`${constants.SOCKET_PROJECT_SYNC_COMPLETED}`, (e) => {
  store.dispatch('projects/fetchPaginatedProjectsList', {
    params: {
      limit: 10,
      startPage: 0,
    },
  });
  store.dispatch('notifications/recordMessage', e);
});
organizationChannel.bind(`${constants.SOCKET_BUDGET_SYNC_COMPLETED}`, (e) => {
  store.dispatch('projects/fetchProjectBudgets', {
    projectId: e.project_id,
  });
  store.dispatch('notifications/recordMessage', e);
});
organizationChannel.bind(`${constants.SOCKET_APP_USER_GROUP_UPDATED}`, (e) => {
  store.dispatch('appusers/fetchSavedAppUsersStructure');
});
organizationChannel.bind(
  `${constants.SOCKET_TIME_SHEET_UPLOAD_SUCCESS}`,
  (e) => {
    store.dispatch('projects/fetchInstanceTimeSheets', {
      trackingItemInstanceId: e.performance_tracking_instance_id,
      params: {
        params: {
          limit: 10,
          startPage: 0,
        },
      },
    });
    store.dispatch('notifications/recordMessage', e);
  },
);
organizationChannel.bind(`${constants.SOCKET_TICKET_COMMENT_CREATED}`, (e) => {
  store.dispatch('support/fetchSupportTicketChats', {
    ticketId: e.ticket_id,
  });
  store.dispatch('notifications/recordMessage', e);
});
organizationChannel.bind(`${constants.SOCKET_APP_USER_REQUEST_ACCEPT}`, (e) => {
  store.dispatch('appusers/fetchAppUsers', {
    params: {
      params: {
        limit: 10,
        startPage: 0,
      },
    },
  });
  store.dispatch('appusers/fetchPendingAppUsers', {
    params: {
      params: {
        limit: 10,
        startPage: 0,
      },
    },
  });
  store.dispatch('notifications/recordMessage', e);
});

export default pusher;
